import { atom } from 'jotai';
import { atomFamily, atomWithDefault, loadable, selectAtom } from 'jotai/utils';
import { dequal } from 'dequal/lite';
import * as Sentry from '@sentry/react';

import { atomFamilyWithDefault, createSimpleState, createState } from '@rewardopl/jotai';
import { get as networkGet } from '@rewardopl/utils/network';
import { stringify } from '@rewardopl/utils/url';

import { APP_ID, VENDOR_BUSINESS_ID, VENDOR_CARD_ID } from './constants';

import { PROD } from './env';

import type { SetStateAction } from 'jotai';
import type {
  ActiveCardSubscription,
  Business,
  Card,
  CardSubscription,
  Challenge,
  ConfigLogin,
  CouponActivation,
  CouponWithDynamicProperties,
  HeavilyFilteredUser,
  Level,
  Message,
  NotRedeemedRewardTransaction,
  Offer,
  PlaceWithDynamicPropertiesForUser,
  PointsTransaction,
  PollWithDynamicProperties,
  ProductWithDynamicPropertiesForUser,
  PromotionGroup,
  QuizWithDynamicProperties,
  Reward,
  RewardTransaction,
  Transaction,
  UnreadMessage,
  User,
} from '@rewardopl/types';

type ChallengeRanking = { user: HeavilyFilteredUser; score: number }[];

export const configLoginState = atom(
  () =>
    networkGet('/api/config/login', {
      headers: {
        'app-id': APP_ID,
      },
    }) as Promise<ConfigLogin>,
);

export const configLoginLoadable = loadable(configLoginState);

export const maybeCurrentUserState = atomWithDefault<User | null | Promise<User | null>>(
  (_get, { setSelf }) => {
    function syncUser(user: User | null): void {
      if (PROD) {
        Sentry.setUser(user ? { id: user._id, email: user.email } : null);
      }

      localStorage.setItem('user', JSON.stringify(user));
    }

    async function getUser(): Promise<User | null> {
      let response = null;
      try {
        response = await (networkGet('/api/users/current') as Promise<User>);
      } catch {}

      syncUser(response);

      return response;
    }

    const rawLocalStorageValue = localStorage.getItem('user');

    if (rawLocalStorageValue) {
      // Update the user in the background while we return the cached value
      getUser().then((nextValue) => {
        if (JSON.stringify(nextValue) !== rawLocalStorageValue) {
          setSelf(nextValue);
        }
      });

      try {
        const localStorageValue = JSON.parse(rawLocalStorageValue) as User | null;

        syncUser(localStorageValue);

        return localStorageValue;
      } catch {}
    }

    return getUser();
  },
);

export const maybeCurrentUserLoadable = loadable(maybeCurrentUserState);

export const currentUserState = atom(
  async (get) => {
    const maybeUser = await get(maybeCurrentUserState);

    if (!maybeUser) {
      throw new Error('User not found');
    }

    return maybeUser;
  },
  (_get, set, nextValue: User) => {
    set(maybeCurrentUserState, nextValue);
  },
);

export const currentUserIdState = atom(async (get) => (await get(currentUserState))._id);

export const maybeBusinessQuery = atomFamily((id: string | undefined) =>
  atom(() => (id ? (networkGet(`/api/businesses/${id}`) as Promise<Business>) : null)),
);

export const businessQuery = atomFamily((id: string) =>
  atom(async (get) => {
    const business = await get(maybeBusinessQuery(id));

    if (!business) {
      throw new Error('Business not found');
    }

    return business;
  }),
);

export const { itemsAtom: cardSubscriptionsState, itemAtomFamily: cardSubscriptionQuery } =
  createSimpleState(
    async (get) =>
      networkGet(`/api/users/${await get(currentUserIdState)}/card_subscriptions`) as Promise<
        CardSubscription[]
      >,
    (id) => async (get) =>
      networkGet(
        `/api/users/${await get(currentUserIdState)}/card_subscriptions/${id}`,
      ) as Promise<CardSubscription>,
  );

const { itemsAtom: messagesState, itemAtomFamily: messageQuery } = createSimpleState(
  async (get) =>
    networkGet(`/api/users/${await get(currentUserIdState)}/messages`) as Promise<Message[]>,
  (id) => async (get) =>
    networkGet(`/api/users/${await get(currentUserIdState)}/messages/${id}`) as Promise<Message>,
);

export const messagePollQuery = atomFamilyWithDefault(
  (id) => async (get) =>
    networkGet(
      `/api/users/${await get(currentUserIdState)}/messages/${id}/poll`,
    ) as Promise<PollWithDynamicProperties | null>,
);

export const { itemsAtomFamily: placeProductsQuery, itemAtomFamily: placeProductQuery } =
  createState(
    (id) => () =>
      networkGet(`/api/places/${id}/products`) as Promise<ProductWithDynamicPropertiesForUser[]>,
    ([baseId, id]) =>
      () =>
        networkGet(
          `/api/places/${baseId}/products/${id}`,
        ) as Promise<ProductWithDynamicPropertiesForUser>,
  );

export const { itemsAtomFamily: transactionsQuery, itemAtomFamily: transactionQuery } = createState(
  (param: string | { id: string; offset: number; limit: number }) => () => {
    const { id, ...rest } = typeof param === 'string' ? { id: param } : param;

    return networkGet(`/api/card_subscriptions/${id}/transactions${stringify(rest)}`) as Promise<
      Transaction[]
    >;
  },
  ([baseId, id]) =>
    () =>
      networkGet(`/api/card_subscriptions/${baseId}/transactions/${id}`) as Promise<Transaction>,
);

export const vendorBusinessState = atom(async (get) => get(businessQuery(VENDOR_BUSINESS_ID)));

export const vendorCardState = atom(
  () => networkGet(`/api/cards/${VENDOR_CARD_ID}`) as Promise<Card>,
);

export const vendorCardLoadable = loadable(vendorCardState);

export const { itemsAtom: vendorCardChallengesState, itemAtomFamily: vendorCardChallengeQuery } =
  createSimpleState(
    () => networkGet(`/api/cards/${VENDOR_CARD_ID}/challenges`) as Promise<Challenge[]>,
    (id) => () => networkGet(`/api/cards/${VENDOR_CARD_ID}/challenges/${id}`) as Promise<Challenge>,
  );

export const vendorCardChallengeRankingQuery = atomFamilyWithDefault(
  (id) => () =>
    networkGet(
      `/api/cards/${VENDOR_CARD_ID}/challenges/${id}/ranking`,
    ) as Promise<ChallengeRanking>,
);

export const vendorCardChallengeQuizQuery = atomFamilyWithDefault(
  (id) => () =>
    networkGet(
      `/api/cards/${VENDOR_CARD_ID}/challenges/${id}/quiz`,
    ) as Promise<QuizWithDynamicProperties | null>,
);

export const { itemsAtom: vendorCardCouponsState, itemAtomFamily: vendorCardCouponQuery } =
  createSimpleState(
    () =>
      networkGet(`/api/cards/${VENDOR_CARD_ID}/coupons`) as Promise<CouponWithDynamicProperties[]>,
    (id) => () =>
      networkGet(
        `/api/cards/${VENDOR_CARD_ID}/coupons/${id}`,
      ) as Promise<CouponWithDynamicProperties>,
  );

export const {
  itemsAtomFamily: vendorCardCouponActivationsQuery,
  itemAtomFamily: vendorCardCouponActivationQuery,
} = createState(
  (id) => () =>
    networkGet(`/api/cards/${VENDOR_CARD_ID}/coupons/${id}/coupon_activations`) as Promise<
      CouponActivation[]
    >,
  ([baseId, id]) =>
    () =>
      networkGet(
        `/api/cards/${VENDOR_CARD_ID}/coupons/${baseId}/coupon_activations/${id}`,
      ) as Promise<CouponActivation>,
);

export const { itemsAtom: vendorCardOffersState, itemAtomFamily: vendorCardOfferQuery } =
  createSimpleState(
    () => networkGet(`/api/cards/${VENDOR_CARD_ID}/offers`) as Promise<Offer[]>,
    (id) => () => networkGet(`/api/cards/${VENDOR_CARD_ID}/offers/${id}`) as Promise<Offer>,
  );

export const vendorCardPromotionGroupsState = atom(
  async () =>
    networkGet(`/api/cards/${VENDOR_CARD_ID}/promotion_groups`) as Promise<PromotionGroup[]>,
);

export const { itemsAtom: vendorCardRewardsState, itemAtomFamily: vendorCardRewardQuery } =
  createSimpleState(
    () => networkGet(`/api/cards/${VENDOR_CARD_ID}/rewards`) as Promise<Reward[]>,
    (id) => () => networkGet(`/api/cards/${VENDOR_CARD_ID}/rewards/${id}`) as Promise<Reward>,
  );

export const vendorCardSubscriptionState = atom(
  async (get) => {
    const cardSubscriptions = await get(cardSubscriptionsState);

    const vendorCardSubscription = cardSubscriptions.find(
      (cardSubscription) => cardSubscription.active && cardSubscription.card_id === VENDOR_CARD_ID,
    );

    if (!vendorCardSubscription) {
      throw new Error('Card subscription not found');
    }

    return vendorCardSubscription;
  },
  (_get, set, nextValueOrSetter: SetStateAction<CardSubscription>) => {
    set(cardSubscriptionsState, (prevCardSubscriptions) =>
      prevCardSubscriptions.map((cardSubscription) =>
        cardSubscription.card_id === VENDOR_CARD_ID
          ? typeof nextValueOrSetter === 'function'
            ? nextValueOrSetter(cardSubscription)
            : nextValueOrSetter
          : cardSubscription,
      ),
    );
  },
);

export const vendorCardSubscriptionBalanceState = selectAtom(
  vendorCardSubscriptionState,
  async (vendorCardSubscription) => (await vendorCardSubscription).balance,
  (async (aPromise: Promise<number>, bPromise: Promise<number>) => {
    const [a, b] = await Promise.all([aPromise, bPromise]);

    return a === b;
  }) as unknown as (a: Promise<number>, b: Promise<number>) => boolean,
);

export const vendorCardSubscriptionIdState = selectAtom<Promise<CardSubscription>, Promise<string>>(
  vendorCardSubscriptionState,
  async (vendorCardSubscription) => (await vendorCardSubscription)._id,
  (async (aPromise: Promise<string>, bPromise: Promise<string>) => {
    const [a, b] = await Promise.all([aPromise, bPromise]);

    return a === b;
  }) as unknown as (a: Promise<string>, b: Promise<string>) => boolean,
);

export const vendorCardSubscriptionCurrentLevelState = atom(async (get) => {
  const cardSubscriptionId = await get(vendorCardSubscriptionIdState);

  return networkGet(
    `/api/card_subscriptions/${cardSubscriptionId}/levels/current`,
  ) as Promise<Level>;
});

export const vendorCardSubscriptionNextLevelState = atom(async (get) => {
  const cardSubscriptionId = await get(vendorCardSubscriptionIdState);

  return networkGet(`/api/card_subscriptions/${cardSubscriptionId}/levels/next`) as Promise<Level>;
});

export const vendorCardSubscriptionPreviousLevelState = atom(async (get) => {
  const cardSubscriptionId = await get(vendorCardSubscriptionIdState);

  return networkGet(
    `/api/card_subscriptions/${cardSubscriptionId}/levels/previous`,
  ) as Promise<Level>;
});

function isVendorMessage(message: Message): message is Message & { business_id: Business['_id'] } {
  return message.business_id === VENDOR_BUSINESS_ID;
}

export const vendorMessagesState = atom(
  async (get) => {
    const messages = await get(messagesState);

    return messages.filter(isVendorMessage);
  },
  (_get, set, nextValueOrSetter: SetStateAction<Message[]>) => {
    set(messagesState, nextValueOrSetter);
  },
);

export const vendorMessageQuery = atomFamily((id: string) =>
  atom(
    async (get) => {
      const message = await get(messageQuery(id));

      if (!isVendorMessage(message)) {
        throw new Error('Message not found');
      }

      return message;
    },
    (_get, set, nextValueOrSetter: SetStateAction<Message>) => {
      set(messageQuery(id), nextValueOrSetter);
    },
  ),
);

export const vendorMessagePollQuery = messagePollQuery;

const defaultVendorPlacesQuery = atomFamily(
  (
    params:
      | {
          categories?: string;
          includePartners?: boolean;
          includePlacesWithoutLocation?: boolean;
          lat?: number;
          limit?: number;
          lng?: number;
          search?: string;
        }
      | undefined = {},
  ) =>
    atomWithDefault<
      PlaceWithDynamicPropertiesForUser[] | Promise<PlaceWithDynamicPropertiesForUser[]>
    >(
      () =>
        networkGet(`/api/businesses/${VENDOR_BUSINESS_ID}/places${stringify(params)}`) as Promise<
          PlaceWithDynamicPropertiesForUser[]
        >,
    ),
  dequal,
);

export const vendorPlacesQuery = atomFamily(
  (
    params:
      | {
          categories?: string;
          includePartners?: boolean;
          includePlacesWithoutLocation?: boolean;
          lat?: number;
          limit?: number;
          lng?: number;
          search?: string;
        }
      | undefined,
  ) =>
    atom(
      async (get) => get(defaultVendorPlacesQuery(params)),
      (_get, set, nextValueOrSetter: SetStateAction<PlaceWithDynamicPropertiesForUser[]>) => {
        set(
          defaultVendorPlacesQuery(params),
          typeof nextValueOrSetter === 'function'
            ? async (prevValueOrPromise) => {
                const prevValue = await prevValueOrPromise;

                return nextValueOrSetter(prevValue);
              }
            : nextValueOrSetter,
        );
      },
    ),
  dequal,
);

export const { itemsAtom: vendorPlacesState, itemAtomFamily: vendorPlaceQuery } = createSimpleState(
  () =>
    networkGet(`/api/businesses/${VENDOR_BUSINESS_ID}/places`) as Promise<
      PlaceWithDynamicPropertiesForUser[]
    >,
  (id) => () =>
    networkGet(
      `/api/businesses/${VENDOR_BUSINESS_ID}/places/${id}`,
    ) as Promise<PlaceWithDynamicPropertiesForUser>,
);

export const { itemsAtom: vendorProductsState, itemAtomFamily: vendorProductQuery } =
  createSimpleState(
    () =>
      networkGet(`/api/businesses/${VENDOR_BUSINESS_ID}/products`) as Promise<
        ProductWithDynamicPropertiesForUser[]
      >,
    (id) => () =>
      networkGet(
        `/api/businesses/${VENDOR_BUSINESS_ID}/products/${id}`,
      ) as Promise<ProductWithDynamicPropertiesForUser>,
  );

function isActiveCardSubscription(
  cardSubscription: CardSubscription,
): cardSubscription is ActiveCardSubscription {
  return cardSubscription.active;
}

export const activeCardSubscriptionsState = atom(async (get) =>
  (await get(cardSubscriptionsState)).filter(isActiveCardSubscription),
);

export const cardSubscriptionBalanceQuery = atomFamily((id: string) =>
  atom(async (get) => {
    const cardSubscription = await get(cardSubscriptionQuery(id));

    return cardSubscription.balance;
  }),
);

export const likedVendorPlacesState = atom(async (get) => {
  const places = await get(vendorPlacesQuery({ includePlacesWithoutLocation: true }));

  return places.filter((place) => place.is_liked_by_current_user);
});

export const likedVendorProductsState = atom(async (get) => {
  const products = await get(vendorProductsState);

  return products.filter((product) => product.is_liked_by_current_user);
});

function getIsPointsTransaction(transaction: Transaction): transaction is PointsTransaction {
  return transaction.type === 'points';
}

export const pointsTransactionsQuery = atomFamily(
  (param: string | { id: string; offset: number; limit: number }) =>
    atom(async (get) => {
      const transactions = await get(transactionsQuery(param));

      return transactions.filter(getIsPointsTransaction);
    }),
  dequal,
);

function getIsRewardTransaction(transaction: Transaction): transaction is RewardTransaction {
  return transaction.type === 'reward';
}

function getIsNotRedeemedRewardTransaction(
  transaction: Transaction,
): transaction is NotRedeemedRewardTransaction {
  return getIsRewardTransaction(transaction) && !transaction.redeemed;
}

export const redeemableTransactionsQuery = atomFamily((id: string) =>
  atom(async (get) => {
    const transactions = await get(transactionsQuery(id));

    return transactions.filter(getIsNotRedeemedRewardTransaction);
  }),
);

function isUnreadMessage(message: Message): message is UnreadMessage {
  return !message.is_read;
}

export const unreadVendorMessagesCountState = atom(async (get) => {
  const messages = await get(vendorMessagesState);

  return messages.filter(isUnreadMessage).length;
});

export const unreadVendorMessagesCountLoadable = loadable(unreadVendorMessagesCountState);

export const pendingCouponRefreshesState = atom<Card['_id'][]>([]);

export const pendingRewardRefreshesState = atom<Card['_id'][]>([]);

export const vendorUrgentMessagesState = atom(
  async (get) => {
    const messages = await get(vendorMessagesState);

    return messages.filter((message) => message.is_urgent);
  },
  (_get, set, nextValueOrSetter: SetStateAction<Message[]>) => {
    set(messagesState, nextValueOrSetter);
  },
);
