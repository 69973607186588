import { Navigate, useParams } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import T from '@wojtekmaj/react-t';

import {
  ImageAndContentWrapper,
  Image,
  ContentWrapper,
  NameWrapper,
  Name,
  Link,
} from './index.styles';

import PageWrapper from '../../../../../../page_wrapper';
import HeaderWithButton from '../../../../header_with_button';
import PlaceInfo from './place_info';
import Products from './products';
import PlaceLikeButton from '../../../../../../common/place_like_button';

import { vendorBusinessState, vendorPlaceQuery } from '../../../../../../../store';

import type { Place } from '@rewardopl/types';

export default function PlacePage() {
  const { place_id: placeId } = useParams() as { place_id?: Place['_id'] };

  if (!placeId) {
    throw new Error('placeId is undefined');
  }

  const place = useAtomValue(vendorPlaceQuery(placeId));

  const business = useAtomValue(vendorBusinessState);

  if (!place) {
    return <Navigate to=".." />;
  }

  const placeWithDefaults = {
    ...business,
    ...place,
  };

  return (
    <PageWrapper withPadding={false}>
      <HeaderWithButton extraButton={<PlaceLikeButton placeId={place._id} showLabel={false} />}>
        Restaurants
      </HeaderWithButton>
      <ImageAndContentWrapper>
        {placeWithDefaults.picture ? (
          <Image src={`/api/uploads/${placeWithDefaults.picture}`} alt={placeWithDefaults.name} />
        ) : null}
        <ContentWrapper>
          <NameWrapper>
            <Name>{place.name}</Name>
            <Link to={`/map/${placeId}`}>
              <T>See on a map</T>
            </Link>
            <PlaceInfo place={placeWithDefaults} />
          </NameWrapper>
          <Products placeId={placeId} />
        </ContentWrapper>
      </ImageAndContentWrapper>
    </PageWrapper>
  );
}
