import { useState } from 'react';
import { useAtom } from 'jotai';

import { post } from '@rewardopl/utils/network';

import LikeButton from './like_button';

import { vendorPlaceQuery } from '../../store';

import type { Place, PlaceWithDynamicPropertiesForUser } from '@rewardopl/types';

type PlaceLikeButtonProps = {
  placeId: Place['_id'];
  showLabel?: boolean;
};

export default function PlaceLikeButton({ placeId, showLabel }: PlaceLikeButtonProps) {
  const [place, setPlace] = useAtom(vendorPlaceQuery(placeId));

  const { is_liked_by_current_user: isLiked } = place;

  const [optimisticIsLiked, setOptimisticIsLiked] = useState<boolean | undefined>(isLiked);

  const isLikedWithOptimisticIsLiked = optimisticIsLiked ?? isLiked;

  const action = `/api/places/${place._id}/${isLikedWithOptimisticIsLiked ? 'unlike' : 'like'}`;

  async function onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    setOptimisticIsLiked(!isLikedWithOptimisticIsLiked);

    try {
      const response = await post(action);

      setPlace(response as PlaceWithDynamicPropertiesForUser);
    } finally {
      setOptimisticIsLiked(undefined);
    }
  }

  return (
    <LikeButton isLiked={isLikedWithOptimisticIsLiked} onClick={onClick} showLabel={showLabel} />
  );
}
